<template>
  <div class="homePageWidth">
    <v-row>
      <!-- ===== About Company ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12">
            <p class="aboutTitle">About DigiPedZGen</p>
            <p class="aboutDescription">
              COVID-19 crises led to a fast digitization of the learning process in most European countries. 
              The students, teachers and educational stakeholders supporting the school system had to deal with the situation very quickly, even though they were not ready. 
              In recent months, as a result of the interruption of the educational process in schools, teachers and students have been placed in an unfamiliar, unconventional, and challenging environment. 
              The transition to distance learning has tested the ability of school leaders, teachers, and students to adapt flexibly to a learning situation in which participants are spatially separated and the interaction between them is mediated. 
              The efforts that were made in the whole sphere of education were enormous. 
              Knowledge and lessons learned to describe a demand for using learning resources in a good pedagogical way and to equip teachers/educators with the skills / knowledge of developing their own digital materials.
              Digital friendly schools is also to prepare not only the teachers, but also school management and staff, students and parents to avoid some lessons learned from spring 2020.
            </p> 
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- ===== Aims and Results ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
            <v-row>
              <!-- Main Aim -->
              <v-col cols="12" >
                <p class="goalTitle">Main aim</p>
                <p class="goalDescription">The main objective is to develop Digital Friendly Schools which have a clear Digital Pedagogical Strategy, digital pedagogical competences of educators, enabling them to deliver high quality inclusive digital education and to better use digital content such as online resources and tools as part of their teaching.  </p>
              </v-col>
              <!-- Aims -->
              <v-col cols="12" class="mb-15">
                <p class="goalTitle">Aims</p>
                <p class="goalDescription">The project aims to: </p>
                <p class="goalDescription">1.	broaden project partners expertise to support and implement Digital Friendly Schools strategies and content for school management, teachers, students and parents. </p>
                <p class="goalDescription">2.	enable each school to create their own Digital Pedagogical Strategy. </p>
                <p class="goalDescription">3.	develop a web-based mapping tool score the Digital Pedagogical Strategy Preparedness and provide results in a SWOT model enabling schools to deal with the right issues to improve them self. </p>
                <p class="goalDescription">4.	Create E-Activities within digital pedagogical practical knowledge and digital pedagogical guidelines with visual supportive steps for practical creation of learning modules giving students holistic learning experience. Equip teachers with the skills / knowledge of developing their own digital materials.  </p>
                <p class="goalDescription">5.	develop a complete resource center for developing and uploading digital learning material. transmission processes. </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- ===== Results ===== -->
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <p class="goalTitle">Results</p>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in results" :key="index">
                <v-expansion-panel-header>
                  <p class="goalDescription" style="font-weight:bold;">{{item.title}}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre class="goalDescription" style="white-space: pre-wrap;">{{item.description}}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue"
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue"
export default {
  name: 'About',
  components:{
    LanguageTest,
    SelectLocale
  },
  data(){
    return {
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" }
      ],
      results: [
        {
          title: "Output 1 - Digital Pedagogical Strategy",
          description: `This output will highlight the most important factors in a strategy in order to enable each school to create their own Digital Pedagogical Strategy. 
Those will consist of methods, tools, guidelines and checklists.
In addition, the project will develop a web-based mapping tool supporting the developed criteria's to score the Digital Pedagogical Strategy Preparedness and provide results in a SWOT model enabling schools to deal with the right issues to improve them self.`
        },
        {
          title: "Output 2 - Teachers Digitale Knowledge",
          description: `Project will create a pedagogical model about how teachers can provide a positive digital environment and develop guidelines for practical use of open educational resources and equip teachers with digital skills to implement their ideas by:
-   focus on teacher practice as the main contributor to improve student's learning outcomes
-   improvement, focused to assist teachers with their digital teaching
-   evolving and will be expanded through showcasing best digital practices.  

All developed knowledge and guidelines will be put together in a tool kit. 
Created tutorial videos will be open available in projects video channel and digital resources on project web site.`
        },
        {
          title: "Output 3 - Teachers as digital learning designer",
          description: `Project will first develop an unique digital resource center, which meets the partners and stakeholder need for both creation and distribution of digital learning content. 
The resource center will also have results from output 1 and 2. 
Project would like the resource centre to be a place to create and share content among schools.
Project will create digital learning content based on developed pedagogical principle, with use of suggested educational software knowledge and development guidelines for practical use of educational resources.`
        },
      ]
    }
  }
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}

/* About Company */
.aboutTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6A6A6A;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: center; 
  background-color: #E0F1FF;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}
.whomBackground {
  background-color: #F2F2F2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

</style>