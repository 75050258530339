<template>
  <div class="container">
    <p class="mt-15 title">Mapping Tool</p>
    <p>Welcome to the DigiZPedGen mapping tool, the ultimate solution for schools aiming to enhance their digital educational potential.</p>
    <p>Unlock your school's full potential and embark on a transformative journey towards digital improvement.</p>
    <p>Explore our powerful mapping tool today and pave the way for a brighter educational future.</p>
    <v-row>
      <v-col cols="12"></v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(card, index) in mappingCards" :key="index">
        <v-hover v-slot:default="{ hover }">
          <v-card height="100%" flat :elevation="hover ? 12 : 2" @click="$refs.openMappingDialog.openingMappingForm(card.id)">
            <v-img v-if="card.id == 138" class="ma-1" :src="card.flag" :alt="card.flagDescription" height="auto" width="auto" cover>
              <p class="pa-5 mt-5 text-center mb-5" style="height:60px; font-weight: bold; font-size: 18px;"> {{ card.title }}</p>
              <v-card-text class="pt-15" style="height: 200px; font-weight: bold; font-size: 18px;">
                <p class="pt-3 text-center">{{ card.description }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="pa-5" dark @click="$refs.openMappingDialog.openingMappingForm(card.id)"> {{card.buttonText}} </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-img>
            <v-img v-else :src="card.flag" :alt="card.flagDescription" height="auto" width="auto" cover>
              <p class="pa-5 mt-5 text-center mb-5" style="height:60px; font-weight: bold; font-size: 18px;"> {{ card.title }}</p>
              <v-card-text class="pt-15" style="height: 200px; font-weight: bold; font-size: 18px;">
                <p class="pt-3 text-center">{{ card.description }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="pa-5" dark @click="$refs.openMappingDialog.openingMappingForm(card.id)"> {{card.buttonText}} </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>

    <MappingToolMainComponent ref="openMappingDialog" />
  </div>
</template>

<script>
import MappingToolMainComponent from '@/components/MappingTool/MappingToolMainComponent.vue';

export default {
  components: {
    MappingToolMainComponent
  },
  data() {
    return {
      accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
      courseCollections:[],
      mappingCards: [
        {
          title: "Mapping tool for Digital Strategy",
          description: "Click here to register and start mapping",
          // id: 138 196,
          id: 138,
          buttonText: "Start",
          flag: require('@/assets/flags/EnglishCard2.jpg'),
          flagDescription: "English Flag"
        },
        {
          title: "Уеб базиран инструмент за установяване на настоящи потребности при дигитализацията на учебния процес",
          description: "Регистриране и стартиране на оценяването",
          id: 199,
          buttonText: "Започни",
          flag: require('@/assets/flags/BulgarianCard2.jpg'),
          flagDescription: "Bulgarian Flag"
        },
        {
          title: "Kartleggingsverktøy for digital strategi",
          description: "Klikk for å registrere og starte kartleggingen",
          id: 201,
          buttonText: "Start",
          flag: require('@/assets/flags/NorwegianCard2.jpg'),
          flagDescription: "Norwegian Flag"
        },
      ]
    }
  },
  mounted(){
    this.getAllCourses();
  },
  methods: {
    getAllCourseCollections(){
      this.courseCollections = [];
      this.$http.get(`https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${this.tenant}&user_id=${null}`,{headers:{tempaccess:this.accessKey}}).then(response =>{
        response.data.forEach(item =>{
          item.collection_content = []
          this.courseCollections.push(item);
        })
      })
    },

    getAllCourses(){
      this.courseCollections = [];
      this.$http.get(`https://app.followup.prios.no/api/courses/collection?tenant_id=107`,{headers:{Tempaccess:this.accessKey}}).then((response) => {
        this.courseCollections = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>