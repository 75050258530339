<template>
  <div class="homePageWidth">
    <v-row class="mb-15">
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <p class="title text-center my-15">Outputs</p>

        <v-expansion-panels style="width: 100%">
          <v-expansion-panel style="width: 100%">
            <v-expansion-panel-header style="width: 100%">
              <v-card-title
                >Output 1 - Digital Pedagogical Strategy</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                This output will highlight the most important factors in a
                strategy in order to enable each school to create their own
                Digital Pedagogical Strategy.
              </p>
              <p>
                Those will consist of methods, tools, guidelines and checklists.
              </p>
              <p>
                In addition, the project will develop a web-based mapping tool
                supporting the developed criteria's to score the Digital
                Pedagogical Strategy Preparedness and provide results in a SWOT
                model enabling schools to deal with the right issues to improve
                them self.
              </p>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card class="ma-0 pa-0" flat>
                    <!-- Image from assets who covers the cart -->
                    <v-img
                      src="@/assets/images/picture.jpg"
                      height="400px"
                      width="100%"
                      class="ma-0 pa-0"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <p class="title">Click language to see PDF</p>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-btn
                    class="ma-3"
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/a6aa8219-c4e1-4612-8359-07180c8e1dd3.pdf"
                    target="_blank"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    English
                  </v-btn>
                  <v-btn
                    class="ma-3"
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4a26ab6d-e97c-4989-8f69-4983a7e526c1.pdf"
                    target="_blank"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    Norwegian
                  </v-btn>
                  <v-btn
                    class="ma-3"
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ca43e5bd-250a-4509-b821-7499c972c0c9.pdf"
                    target="_blank"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    Bulgarian
                  </v-btn>
                  <v-btn
                    class="ma-3"
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/aed65654-c978-4715-8dcd-08b1081aefab.pdf"
                    target="_blank"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    Lithaunian
                  </v-btn>
                  <v-btn
                    class="ma-3"
                    href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/75d6f07d-522e-4188-8041-17fcca04d56e.pdf"
                    target="_blank"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    Polish
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="width: 100%">
            <v-expansion-panel-header style="width: 100%">
              <v-card-title
                @click="$refs.openOutputTwoDialog.openOutputDialog()"
              >
                Output 2 - Teachers Digitale Knowledge</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- <p>
                Project will create a pedagogical model about how teachers can
                provide a positive digital environment and develop guidelines
                for practical use of open educational resources and equip
                teachers with digital skills to implement their ideas by:
              </p>
              <p class="mb-0 ml-2">
                - focus on teacher practice as the main contributor to improve
                student's learning outcomes
              </p>
              <p class="mb-0 ml-2">
                - improvement, focused to assist teachers with their digital
                teaching
              </p>
              <p class="ml-2">
                - evolving and will be expanded through showcasing best digital
                practices.
              </p>
              <p>
                All developed knowledge and guidelines will be put together in a
                tool kit.
              </p>
              <p>
                Created tutorial videos will be open available in projects video
                channel and digital resources on project web site.
              </p> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-card style="width: 100%; cursor: pointer">
            <v-card-title
              @click="$refs.openOutputThreeDialog.openOutputDialog()"
            >
              Output 3 - Teachers as digital learning designer</v-card-title
            >
          </v-card>
          <!-- <v-expansion-panel style="width: 100%">
            <v-expansion-panel-header style="width: 100%">
              <v-card-title
                >Output 3 - Teachers as digital learning designer</v-card-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Project will first develop an unique digital resource center,
                which meets the partners and stakeholder need for both creation
                and distribution of digital learning content.
              </p>
              <p>
                The resource center will also have results from output 1 and 2.
              </p>
              <p>
                Project would like the resource centre to be a place to create
                and share content among schools.
              </p>
              <p>
                Project will create digital learning content based on developed
                pedagogical principle, with use of suggested educational
                software knowledge and development guidelines for practical use
                of educational resources.
              </p>
              <a
                href="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/82410bdf-aeca-4c3e-b6f9-ff1f28372adc.pdf"
                target="_blank"
                >Digital Pedagogical Guidelines for Digital pedagogical strategy
                for Z generation</a
              >
            </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
      </v-col>
    </v-row>
    <OutputTwoDialog ref="openOutputTwoDialog"></OutputTwoDialog>
    <OutputThreeDialog ref="openOutputThreeDialog"></OutputThreeDialog>
  </div>
</template>

<script>
import OutputThreeDialog from "@/components/dialogs/outputThreeDialog.vue";
import OutputTwoDialog from "@/components/dialogs/outputTwoDialog.vue";
export default {
  components: {
    OutputTwoDialog,
    OutputThreeDialog,
  },
};
</script>

<style scoped>
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>
