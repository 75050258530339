<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Digital pedagogy
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          All partners must choose and provide at least one resource on digital
          teaching strategies, blended learning, and online learning approaches
          that can help educators integrate digital technology into their
          teaching practices
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionFive"
                  v-html="partnerData.descriptionFive"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionSix"
                  v-html="partnerData.descriptionSix"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionSeven"
                  v-html="partnerData.descriptionSeven"
                  class="mb-5"
                ></p>
                <p
                  v-if="partnerData.descriptionEight"
                  v-html="partnerData.descriptionEight"
                  class="mb-5"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          descriptionOne:
            "How to advance quality online education. <br /> On the link below you can find resources, including research reports, webinars, workshops, and best practices for online teaching and learning. <br /> Educators can explore the OLC's resources to gain a deeper understanding of effective online learning approaches and instructional strategies <a href='https://onlinelearningconsortium.org/' target='_blank'>https://onlinelearningconsortium.org/</a> ",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          descriptionOne:
            "<strong> 1.</strong> The National Electronic Library for Teachers (Национална електронна библиотека на учителите) is a valuable resource in Bulgaria that aims to support teachers in their professional development and provide them with a wide range of educational materials. <br /> Here is some information about the National Electronic Library for Teachers. <a href='https://e-learn.mon.bg/' target='_blank'>https://e-learn.mon.bg/</a>",
          descriptionTwo:
            "It serves as an essential resource for educators, enabling them to access educational materials, enhance their teaching practices, and engage in professional development. <br /> It supports the continuous growth and development of teachers, ultimately benefiting students and the overall education system in Bulgaria.",
          descriptionThree:
            "<strong> 2. </strong> Digital Promise - <a href='https://digitalpromise.org/' target='_blank'>https://digitalpromise.org/</a>",
          descriptionFour:
            "Digital Promise is a global non profit organization that offers a wide variety of learning opportunities for educators. Their mission is to shape the future of learning and advance the education systems. <br /> They provide webinars, blogs, guidelines, research that is relevant to adult learners, adult learning tasks etc.",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          descriptionOne:
            "<a href='https://etwinning.pl/' target='_blank'>https://etwinning.pl/</a> In eTwinning, teachers organise and run on-site and online activities with their students along with colleagues from countries participating in the Erasmus+ programme. <br /> They engage in collaborative projects with the support of the TwinSpace environment.",
          descriptionTwo:
            "<a href='https://www.open.ac.uk/' target='_blank'>https://www.open.ac.uk/</a> OpenLearn is a free learning platform, delivered by The Open University as part of its Royal Charter commitment to support the wellbeing of the community. <br /> We're proud to say that it reaches over 6 million learners a year. <br /> You will find over 1,000 free courses, topical and interactive content, videos and online games on OpenLearn.",
          descriptionThree:
            "<a href='https://www.schooleducationgateway.eu/en/pub/teacher_academy.htm' target='_blank'>https://www.schooleducationgateway.eu/en/pub/teacher_academy.htm</a> School Education Getaway is an online platform for school education. <br /> It includes resources, publications, tutorials and materials that enhance the pedagogical practice. <br /> It also has a Teacher Academy that offers options for on-site courses and online courses. <br /> Participants can join a course and learn about key strategies, how to improve the learning environment, different teaching strategies, personal development and overall get the support they need.",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          descriptionOne:
            "Bank of more than 25000 digital tasks. <br /> A possibility to enrich modern lessons with interesting tests,  prepared by professional authors. Additional, interactive tasks prepared according to textbook material. <br /> Teachers can provide the students with digital tasks from the bank or create digital tasks of their choice, tests according to their level of difficulty. <br /> <a href='https://www.eduka.lt/klase/' target='_blank'>https://www.eduka.lt/klase/</a>",
          descriptionTwo:
            "Edutopia <a href='https://www.edutopia.org/' target='_blank'>https://www.edutopia.org/</a>  Edutopia is a comprehensive website that offers a wealth of resources on various aspects of education. <br /> It has a dedicated section on blended learning and provides articles, videos, and case studies that highlight effective strategies and tools for integrating technology in the classroom.",
          descriptionThree:
            "Common Sense Education <a href='https://www.commonsense.org/education' target='_blank'>https://www.commonsense.org/education</a>   Common Sense Education offers a wide range of resources, lesson plans, and reviews of educational technology tools. <br /> Their website provides guidance on digital citizenship, online safety, and offers a collection of resources for implementing blended learning effectively.",
          descriptionFour:
            "eLearning Industry <a href='https://elearningindustry.com/' target='_blank'>https://elearningindustry.com/</a> is a platform that focuses on online learning and e-learning technologies. <br /> It provides articles, research papers, and resources on e-learning strategies, tools, and trends. <br /> Educators can find valuable information on designing online courses, using learning management systems, and integrating multimedia elements.",
          descriptionFive:
            "International Society for Technology in Education (ISTE) <a href='http://www.iste.org/' target='_blank'>http://www.iste.org/</a>  ISTE is a leading organization that promotes the use of technology in education. <br /> Their website offers resources, webinars, and online courses on various topics, including blended learning, digital teaching strategies, and online learning approaches. <br /> They also provide a set of standards for educators to guide their technology integration efforts.",
          descriptionSix:
            "TeachThought <a href='http://www.teachthought.com/' target='_blank'>http://www.teachthought.com/</a>  TeachThought is an educational blog that covers a wide range of topics related to teaching and learning. <br /> They have a dedicated section on digital learning, which includes articles, guides, and resources on using technology effectively in the classroom. <br /> It also offers insights into emerging trends and innovative approaches to online and blended learning.",
          descriptionSeven:
            "The Learning Accelerator <a href='https://learningaccelerator.org/' target='_blank'>https://learningaccelerator.org/</a>  The Learning Accelerator is an organization that focuses on advancing blended and personalized learning. <br /> Their website provides resources, case studies, and research on blended learning models and strategies. <br /> Educators can find practical guidance on implementing blended learning and integrating digital tools effectively.",
          descriptionEight:
            "EdSurge <a href='http://www.edsurge.com/' target='_blank'>http://www.edsurge.com/</a>  EdSurge is a news and media platform that covers education technology and innovation. <br /> They provide articles, podcasts, and resources that can help educators stay updated on the latest trends and best practices in digital teaching and learning. <br /> They also offer a directory of educational technology products and services.",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          descriptionOne:
            "How use different digital tools and educational resources. <br /> Reflect upon how to integrate them into the teaching and learning process. <br /> - <a href='https://anyflip.com/whhx/npmw/' target='_blank'>https://anyflip.com/whhx/npmw/</a> ",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
