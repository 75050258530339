<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Research and reports
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          All partners provide access to the latest research, reports, and
          publications on digitalization in education, including data and
          insight on the impact and effectiveness of digital initiatives. It
          could be just links to it.
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFive"
                  v-html="partnerData.descriptionFive"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionSix"
                  v-html="partnerData.descriptionSix"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionSeven"
                  v-html="partnerData.descriptionSeven"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionEight"
                  v-html="partnerData.descriptionEight"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionNine"
                  v-html="partnerData.descriptionNine"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTen"
                  v-html="partnerData.descriptionTen"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionEleven"
                  v-html="partnerData.descriptionEleven"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwelve"
                  v-html="partnerData.descriptionTwelve"
                  class="mb-3"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          descriptionOne:
            "<a href='https://educationaltechnologyjournal.springeropen.com/articles/10.1186/s41239-023-00382-w' target='_blank'>https://educationaltechnologyjournal.springeropen.com/articles/10.1186/s41239-023-00382-w</a>",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          descriptionOne:
            "<a href='https://blog.gitnux.com/technology-in-education-statistics/' target='_blank'>https://blog.gitnux.com/technology-in-education-statistics/</a>",
          descriptionTwo:
            "<a href='https://www.softermii.com/blog/the-impact-of-digital-transformation-in-education-sector' target='_blank'>https://www.softermii.com/blog/the-impact-of-digital-transformation-in-education-sector</a>",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          descriptionOne:
            "<a href='https://www.gov.pl/web/edukacja-i-nauka/komunikat-ministra-edukacji-i-nauki-z-dnia-31-maja-2023-o-zmianie-komunikatu-o-ustanowieniu-programu-wsparcia-edukacji' target='_blank'>https://www.gov.pl/web/edukacja-i-nauka/komunikat-ministra-edukacji-i-nauki-z-dnia-31-maja-2023-o-zmianie-komunikatu-o-ustanowieniu-programu-wsparcia-edukacji</a>",
          descriptionTwo:
            "<a href='https://www.gov.pl/web/edukacja-i-nauka/laboratoria-przyszlosci---nowoczesna-edukacja-w-polskich-szkolach--relacje-ze-szkol' target='_blank'>https://www.gov.pl/web/edukacja-i-nauka/laboratoria-przyszlosci---nowoczesna-edukacja-w-polskich-szkolach--relacje-ze-szkol</a>",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          descriptionOne:
            "<a href='https://unesdoc.unesco.org/ark:/48223/pf0000214493' target='_blank'>https://unesdoc.unesco.org/ark:/48223/pf0000214493</a>",
          descriptionTwo:
            "<a href='https://www.researchgate.net/publication/361612793_A_Snapshot_of_Children's_Attitudes_toward_Machine_Translation' target='_blank'>https://www.researchgate.net/publication/361612793_A_Snapshot_of_Children's_Attitudes_toward_Machine_Translation</a>",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          descriptionOne: "Digital judgment in school",
          descriptionTwo:
            "A Norwegian research for about how teachers can work to develop secondary school students' digital judgment",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
