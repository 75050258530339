<template>
  <div>
    <v-row>
      <v-col cols="12" class="mt-5"></v-col>
      <v-col>Digital Pedagogical Strategy</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Digital-Pedagogical-Strategy',
  data(){
    return {
      
    }
  }
}
</script>