<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Training and professional development
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          Links to the separate modules of the MOOC and other professional
          development opportunities for educators interested in enhancing their
          digital skills and knowledge.
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-3"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          descriptionOne:
            "How to design collaborative projects and make learners creators of content?",
          descriptionTwo:
            "<a href='https://digipedzgen.eu/trainingmodules' target='_blank'>https://digipedzgen.eu/trainingmodules</a>",
          descriptionThree:
            "This training module aims to provide teachers with the necessary knowledge and skills to design and implement collaborative projects that enable learners to become creators of content.",
          descriptionFour:
            "Social Media and Online Communities: Join professional learning communities on social media platforms like Twitter, Facebook, or LinkedIn. <br /> Participate in relevant discussions, follow educational technology hashtags, and connect with educators who share similar interests. <br /> You can often find announcements and recommendations for professional development opportunities through these communities.",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          descriptionOne:
            "<a href='https://digipedzgen.eu/trainingmodules' target='_blank'>https://digipedzgen.eu/trainingmodules</a>",
          descriptionTwo:
            "School Education Getaway - <a href='https://www.schooleducationgateway.eu/en/pub/teacher_academy.htm' target='_blank'>https://www.schooleducationgateway.eu/en/pub/teacher_academy.htm</a>",
          descriptionThree:
            "School Education Getaway is an online platform for school education. <br /> It includes resources, publications, tutorials and materials that enhance the pedagogical practice. It also has a Teacher Academy that offers options for on-site courses and online courses. <br /> Participants can join a course and learn about key strategies, how to improve the learning environment, different teaching strategies, personal development and overall get the support they need. ",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          descriptionOne:
            "<a href='https://odnpoznan.pl' target='_blank'>https://odnpoznan.pl</a> a training center offering professional development for teachers of schools and educational institutions in Wielkopolska province, with particular emphasis on methodological advisors, educational management staff, employees of pedagogical supervision and employees of local government units.",
          descriptionTwo:
            "<a href='https://etwinning.pl' target='_blank'>https://etwinning.pl</a> In eTwinning, teachers organize and run on-site and online activities with their students along with colleagues from countries participating in the Erasmus+ programme. <br /> They engage in collaborative projects with the support of the TwinSpace environment.",
          descriptionThree:
            "<a href='https://zpe.gov.pl' target='_blank'>https://zpe.gov.pl</a> Enables the creation of interdisciplinary lessons, authentic, teacher versions of the textbook, group work, individual education. <br /> The platform includes resources for general education at all educational stages and for vocational training.",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          descriptionOne:
            "How to promote mutual exchange of information / interaction within the group?",
          descriptionTwo:
            "<a href='https://digipedzgen.eu/trainingmodules' target='_blank'>https://digipedzgen.eu/trainingmodules</a>",
          descriptionThree:
            "This training module aims to provide teachers with the necessary knowledge and skills to promote mutual exchange of information / interaction within the group.",
          descriptionFour:
            "By implementing such strategies as establishing clear communication channels, encouraging active participation, facilitating structured discussions, fostering collaborative activities, using icebreakers and team-building exercises, providing feedback and encouragement a teacher can create a dynamic and interactive learning environment that encourages the mutual exchange of information and fosters meaningful interactions within the group.",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          descriptionOne: "Access and Motivation",
          descriptionTwo:
            "<a href='https://digipedzgen.eu/trainingmodules' target='_blank'>https://digipedzgen.eu/trainingmodules</a>",
          descriptionThree:
            "This training module emphasizes the importance of ensuring that learners can access the online environment and are motivated to engage. <br /> For teachers, this stage is crucial as it requires creating an inclusive, user-friendly virtual space that sparks interest and commitment in students. <br /> It sets the tone for the entire e-learning journey, making it essential for a successful online education experience.",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
