<template>
  <v-dialog v-model="outputTwoDialog" fullscreen>
    <v-card>
      <v-card-title>
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mdAndDown"
          style="margin-bottom: -14px; color: red"
          class="d-flex justify-center pt-2"
          color="black"
          @click="openDrawer()"
        >
          <v-img
            class="d-flex justify-start"
            height="50px"
            width="50px"
            src="../../assets/list_black_24dp.svg"
          ></v-img>
        </v-app-bar-nav-icon>
        <v-spacer />
        <p
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-6"
          style="font-size: 16px; font-weight: bold"
        >
          Teachers as digital learning designer
        </p>
        <p v-else class="mt-6" style="font-weight: bold">
          Teachers as digital learning designer
        </p>
        <v-spacer />
        <v-btn
          large
          class="closeButtonStyling"
          color="error"
          icon
          @click="closeOutputDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="ma-0 pa-0">
        <!-- To push it down -->
        <v-col cols="12" class="ma-0 pa-0 mt-15"></v-col>
        <!-- Computer menu -->
        <template>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="3"
            class="ma-0 pa-0"
            style="border-right: 2px solid grey"
            height="100%"
          >
            <v-col
              cols="12"
              v-for="(goal, goalIndex) in mainCategoryNavigation"
              :key="goalIndex"
              @click="changeTemplate(goal)"
              class="ma-0 pa-0"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 0"
                  style="cursor: pointer"
                  class="pt-6"
                >
                  <!-- <v-img :src="goal.image" width="500" height="auto"></v-img> -->
                  <v-btn
                    block
                    class="pa-5 my-2"
                    style="background-color: rgb(19, 79, 113); color: orange"
                  >
                    <span style="font-weight: bold"> {{ goal.prefix }} - </span>
                    <span> {{ goal.title }}</span>
                  </v-btn>
                </v-card>
              </v-hover>
            </v-col>
          </v-col>
          <!-- Phone Side Menu -->
          <template
            v-if="$vuetify.breakpoint.mdAndDown && drawerIsOpen == true"
            cols="12"
            class="ma-0 pa-0"
          >
            <v-card
              style="cursor: pointer; width: 99vw"
              flat
              class="pb-5 pt-3 px-2"
            >
              <!-- {{ categoryID }} -->
              <v-col
                cols="12"
                v-for="(goal, goalIndex) in mainCategoryNavigation"
                :key="goalIndex"
                @click="changeTemplate(goal)"
                class="ma-0 pa-0"
              >
                <v-btn
                  block
                  class="pa-5 my-2"
                  style="background-color: rgb(19, 79, 113); color: orange"
                >
                  <span style="font-weight: bold"> {{ goal.prefix }} - </span>
                  <span> {{ goal.title }}</span>
                </v-btn>
              </v-col>
            </v-card>
            <v-col cols="12"></v-col>
          </template>
        </template>
        <v-col
          cols="12"
          xl="9"
          lg="9"
          md="9"
          sm="12"
          xs="12"
          class="ma-0 pa-0 pa-3"
        >
          <template v-if="categoryID == 1">
            <OutputOne />
          </template>
          <template v-if="categoryID == 2">
            <OutputTwo />
          </template>
          <template v-if="categoryID == 3">
            <OutputThree />
          </template>
          <template v-if="categoryID == 4">
            <OutputFour />
          </template>
          <!-- <template v-if="categoryID == 5">
            <OutputFive />
          </template>
          <template v-if="categoryID == 6">
            <OutputSix />
          </template> -->
          <template v-if="categoryID == 7">
            <OutputSeven />
          </template>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import OutputOne from "@/components/OutputSections/OutputOne.vue";
import OutputTwo from "@/components/OutputSections/OutputTwo.vue";
import OutputThree from "@/components/OutputSections/OutputThree.vue";
import OutputFour from "@/components/OutputSections/OutputFour.vue";
import OutputFive from "@/components/OutputSections/OutputFive.vue";
import OutputSix from "@/components/OutputSections/OutputSix.vue";
import OutputSeven from "@/components/OutputSections/OutputSeven.vue";
export default {
  components: {
    OutputOne,
    OutputTwo,
    OutputThree,
    OutputFour,
    OutputFive,
    OutputSix,
    OutputSeven,
  },
  data() {
    return {
      // Dialog State
      outputTwoDialog: false,
      // Phone Drawer State
      drawerIsOpen: false,
      // Category ID used to swap between the different templates
      categoryID: 1,
      // Main Output Categories
      mainCategoryNavigation: [
        {
          prefix: "I",
          description: "Introduction",
          value: 1,
          title: "Digital tools and platforms",
        },
        {
          prefix: "II",
          description: "Digital teaching fundamentals",
          value: 2,
          title: "Digital pedagogy",
        },
        {
          prefix: "III",
          description: "Teachers competencies for digital teaching",
          value: 3,
          title: "Case studies and best practices",
        },
        {
          prefix: "IV",
          description:
            "Tutorial videos on how to apply digital teaching through digital tools",
          value: 4,
          title: "Training and professional development",
        },
        // {
        //   prefix: "V",
        //   description:
        //     "Tutorial videos on how to apply digital teaching through digital tools",
        //   value: 5,
        //   title: "Digital skills development",
        // },
        // {
        //   prefix: "VI",
        //   description:
        //     "Tutorial videos on how to apply digital teaching through digital tools",
        //   value: 6,
        //   title: "Digital skills development",
        // },
        {
          prefix: "V",
          description:
            "Tutorial videos on how to apply digital teaching through digital tools",
          value: 7,
          title: "Research and reports",
        },
      ],
    };
  },
  methods: {
    // Open Dialog
    openOutputDialog() {
      this.outputTwoDialog = true;
      this.categoryID = 1;
    },

    // Close Dialog
    closeOutputDialog() {
      this.outputTwoDialog = false;
    },

    // For Main Template Swaps
    changeTemplate(newTemplate) {
      this.categoryID = newTemplate.value;
      this.mainHeader = newTemplate.description;
    },
    // Open dialog navigation
    openDrawer() {
      if (this.drawerIsOpen == false) {
        this.drawerIsOpen = true;
      } else {
        this.drawerIsOpen = false;
      }
    },
  },
};
</script>
