<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="ma-0 pa-0 pt-16 mt-16">
        <v-card class="ma-0 pa-0 box" flat>
          <p class="ma-0 pa-0 partnerChapterTitle">Partners</p>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-for="(item, index) in projectPartners" :key="index">
        <v-card class="cardBorder ma-2 pa-7 px-2"  height="100%">
          <v-row>
            <v-col cols="12">
              <v-card height="90px" flat class="d-flex" :href="item.link" target="_blank">
                <v-img class="mt-5" :src="item.image" contain />
              </v-card>
            </v-col>
            <v-col class="pb-0 mb-0" cols="12">
              <p class="pl-3 partnerTitle">{{item.title}} 
                <span> 
                  <v-btn icon :href="item.link" target="_blank">
                    <v-icon color="green">mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </p>
            </v-col>
            <v-col v-if="selectedIndex !== index" cols="12" class="ma-0 pa-0 pl-3 pr-3">
              <p class="pl-3 pr-3 partnerDescriptionPre">{{item.description}}</p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon v-if="selectedIndex !== index" @click="openingPartnerExpand(index)">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon v-else @click="closingPartnerExpand()">
              <v-icon large color="black">mdi-chevron-up</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="index === selectedIndex">
              <v-divider></v-divider>
              <v-card-text>
                <p class="partnerDescriptionPost">{{item.description}}</p>
                <a v-if="item.textLink" :href="item.textLink" target="_blank">https://youtu.be/s4DgBdGZ5Bs</a>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        { 
          title: "19 'Elin Pelin' Coordinator", 
          image: "img/19_logo_en.jpg", 
          link: "https://19su.bg/",
          description: `19 Elementary/High School "Elin Pelin" offers primary and secondary advanced learning of entrepreneurship and business as well as languages, especially English, German and Norwegian languages.
19 "Elin Pelin" is an innovative school with 90 years history in which students show rich creative imagination and show an enviable desire to improve their knowledge in various disciplines, and mutual aid and tolerance are leading in our philosophy of working with children. In the school year 2021/2022, about 1,045 students aged 7-19 from 1st to 12th grade are enrolled in the school. The school has a good team of specialists for working with children with special educational needs / about 35 students /. Through technology, the school community successfully uses various interactive classrooms / 18 in number /. All teachers, students and non-educators have created school emails in the school domain. We teach students in person or remotely, depending on the pandemic situation, the learning environment is secure and well protected through a virtual classroom on Google.
Animated video for 19U:
`,
          textLink: "https://youtu.be/s4DgBdGZ5Bs"
        },
        { 
          title: "Prios Kompetanse", 
          image: "img/prioslogo_PNG big_dark.png", 
          link: "https://www.prios.no/",
          description: `Prios Kompetanse AS (Prios) from Steinkjer, Norway is a research-based corporation which aims to implement the idea of lifelong learning, support and conduct development projects. 
The center is open to all who wish to develop their talents, refresh their knowledge, improve their business, needs new or better digital tools or have ideas in need for support to be fulfilled. 
The main sectors and activities is:
-  	Educational activities focused on adult learners and labor market service within basic skills training, VET, entrepreneurship training and tailored training for businesses. 
-	  Software support for their educational activities as well as developing new digital solutions based upon requests and needs in different projects, we participate in. 
For instance, Prios have developed and own the Follow-Up® concept.  
Consulting activities mostly supports business clients within internal innovation processes, HR processes, economical advises and management improvements.`
        },
        { 
          title: "ValueMe", 
          image: "img/valuemelogo_svg-main.png", 
          link: "https://www.valueme.bg/",
          description: `Valueme is an educational training organization that specializes in training and innovation practices in digital skills at an international level. 
Our main aspect is in content creation and effective application of pedagogy through technology essential for inclusive and competence building contemporary education in blended and distance learning.
ValueMe is certified as a Training Organization for Adult in the scope of formal and non-formal training and learning and the quality management system of Valueme is certified according to the requirements of ISO 9001.
ValueMe, already as one of the few institutions in Bulgaria was certified by Executive Agency “Bulgarian Accreditation Service” (EA BAS) to offer trainings and best practices with credits including pedagogical specialists.
As the name suggests, Valueme aims to create value and contribute to the development, management and sustainability of European educational projects by which it can reach and touch the lives of a wider target audience by equipping them with up-to-date digital competencies, content, and problem-solving skills.`
        },
        { 
          title: "LOXIV, Poland ", 
          image: "img/Poland_logo.jpg", 
          link: "http://czternastelo.pl/",
          description: `Kazimierz Wielki High School NO. 14 in Poznań is a public school run by the Poznań City Hall. 
It is situated in the centre of Poznań, in Rataje - a lovely and quiet district, near the river Warta. 
The teaching staff consists of 53 members.
Students can choose from three foreign languages: English, German, Spanish and russian. 
There are 20 classes in the school. 
Each year the school offers classes with extended curriculum in different subjects. 
The newly admitted students learn in classes which focus on different subjects depending on the class profile. 
For a few years, despite the population decline, admission to our new classes has been very successful. 
In 2007 our school joined the activities of the Society of Cooperation with the East MEMORAMUS. 
Students can participate in the annual Memorial March of Polish and Russian youth in Katyn and broaden their knowledge about the experiences of the Poles that used to inhabit, lost their lives or still live in the Eastern Borderlands. 
`
        },
        { 
          title: "Vilnius Municipality's Grigiskes Sviesos Gymnasium, Vilniaus", 
          image: "img/Litauenpartner.png", 
          link: "https://www.gsviesa.lt/",
          description: `In Vilnius Grigiskes Sviesos gymnasium is being educated over 900 students at the age of 7 to 19. 
We have almost 90 teachers at school. 
The history of our school dates back only 30 years. 
English is the first foreign language and German, Russian, French are the second ones (2classes/week). 
English teaching starts in grade 2 primary school. 
We look for deeply involving foreign language teaching strategies to motivate and give consistency to language learning to improve and apply their knowledge. 
We would like to exchange best practices to reduce school dropout and increase our student's learning results. 
We are upgrading our foreign languages knowledge. 
It is taken special care to enhance the study of foreign languages through extracurricular courses to strengthen the student's skills. 
These initiatives aim to integrate in a broader cultural context and to get to high-level training courses. `
        },
      ]
    }
  },
  methods: {
    openingPartnerExpand(index){
      this.selectedIndex = index;
    },

    closingPartnerExpand(){
      this.selectedIndex = null;
    },

  }
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
  margin-bottom: 50px;
}
/* Card CSS */
.box{
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right,  #DD9A30 5%, transparent 5%);
  border-image-slice: 1;
}
.cardBorder{
  border: 3px #329D9C solid;
}


/* Text styling */
.partnerChapterTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.partnerTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #434343;
  letter-spacing: 0px;
  text-align: left;
}
.partnerDescriptionPre {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #6A6A6A;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0px;
}


</style>