<template>
  <div class="homePageWidth">
    <!-- Home Page Content -->
    <v-row>
      <v-col cols="12" class="mt-5"></v-col>
      <!-- Intro Image -->
      <v-col cols="12" class="pb-0">
        <v-card class="introImage">
          <v-img src="../assets/images/laptop-2411303_1280.jpg" height="600">
            <p class="introTitle">Welcome to DigiPedZGen</p>
          </v-img>
        </v-card>
      </v-col>

      <!-- 3 Cards in center of cards -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            v-for="(pageCard, pageCardIndex) in pageCards"
            :key="pageCardIndex"
          >
            <v-card
              class="pageCardPositioning"
              :to="pageCard.link"
              style="position: relative"
            >
              <v-divider
                v-if="pageCardIndex == 0"
                class="pageCardDividerBlue"
              ></v-divider>
              <v-divider
                v-if="pageCardIndex == 1"
                class="pageCardDividerGreen"
              ></v-divider>
              <v-divider
                v-if="pageCardIndex == 2"
                class="pageCardDividerRed"
              ></v-divider>
              <v-row>
                <v-col cols="10">
                  <p class="pt-2 pageCardTitle">{{ pageCard.name }}</p>
                </v-col>
                <v-col cols="2">
                  <v-icon large class="pt-2 pageCardArrow"
                    >mdi-arrow-right</v-icon
                  >
                </v-col>
              </v-row>
              <p class="pageCardDescription">{{ pageCard.description }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Info Text -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"></v-col>
          <v-col cols="12">
            <p class="goalTitle text-center mt-15">Some short info</p>
            <p class="aboutDescription">
              COVID-19 crises led to a fast digitization of the learning process
              in most European countries. The students, teachers and educational
              stakeholders supporting the school system had to deal with the
              situation very quickly, even though they were not ready. In recent
              months, as a result of the interruption of the educational process
              in schools, teachers and students have been placed in an
              unfamiliar, unconventional, and challenging environment. The
              transition to distance learning has tested the ability of school
              leaders, teachers, and students to adapt flexibly to a learning
              situation in which participants are spatially separated and the
              interaction between them is mediated. The efforts that were made
              in the whole sphere of education were enormous. Knowledge and
              lessons learned to describe a demand for using learning resources
              in a good pedagogical way and to equip teachers/educators with the
              skills / knowledge of developing their own digital materials.
              Digital friendly schools is also to prepare not only the teachers,
              but also school management and staff, students and parents to
              avoid some lessons learned from spring 2020.
            </p>
          </v-col>
          <v-col cols="12">
            <p class="goalTitle text-center">Aim</p>
            <p class="goalDescription">
              The main objective is to develop Digital Friendly Schools which
              have a clear Digital Pedagogical Strategy, digital pedagogical
              competences of educators, enabling them to deliver high quality
              inclusive digital education and to better use digital content such
              as online resources and tools as part of their teaching.
            </p>
          </v-col>
          <!-- Aims -->
          <v-col cols="12" class="mb-15">
            <p class="goalTitle text-center">Objectives</p>
            <p class="goalDescription">The project objectives is to:</p>
            <p class="goalDescription">
              1. broaden project partners expertise to support and implement
              Digital Friendly Schools strategies and content for school
              management, teachers, students and parents.
            </p>
            <p class="goalDescription">
              2. enable each school to create their own Digital Pedagogical
              Strategy.
            </p>
            <p class="goalDescription">
              3. develop a web-based mapping tool score the Digital Pedagogical
              Strategy Preparedness and provide results in a SWOT model enabling
              schools to deal with the right issues to improve them self.
            </p>
            <p class="goalDescription">
              4. Create E-Activities within digital pedagogical practical
              knowledge and digital pedagogical guidelines with visual
              supportive steps for practical creation of learning modules giving
              students holistic learning experience. Equip teachers with the
              skills / knowledge of developing their own digital materials.
            </p>
            <p class="goalDescription">
              5. develop a complete resource center for developing and uploading
              digital learning material. transmission processes.
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- News -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <NewsMainPage></NewsMainPage>
      </v-col>
      <!-- See all news button - go to page -->
      <v-col cols="12" align="center" class="mt-2">
        <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news">
          See all news
        </v-btn>
      </v-col>

      <!-- To keep the structure -->
      <v-col cols="12"></v-col>

      <!-- RSS -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <RssForHome></RssForHome>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import RssForHome from "@/components/rss/rssForHome.vue";
import NewsMainPage from "@/components/News/NewsMainPage.vue";
export default {
  name: "Home",
  components: {
    RssForHome,
    NewsMainPage,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* Intro Classes for box and text at the top */
.introImage {
  /* height:650px; */
  background-color: rgb(213, 211, 211);
}
.introTitle {
  font-size: 48px;
  color: white;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 15%;
  margin-top: 10%;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  text-shadow: 2px 2px #0e3196, -2px -2px #0e3196, -2px 2px #0e3196,
    2px -2px #0e3196;
}
.introDescription {
  font-size: 28px;
  color: white;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 15%;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  text-shadow: 2px 2px #0e3196, -2px -2px #0e3196, -2px 2px #0e3196,
    2px -2px #0e3196;
}
/* Styling the 3 cards close to the intro top */
.pageCardPositioning {
  position: relative;
  top: -5em;
  right: 50;
  padding: 10px;
}
.pageCardDividerBlue {
  background-color: #205072;
  padding: 2px;
}
.pageCardDividerGreen {
  background-color: #329d9c;
  padding: 2px;
}
.pageCardDividerRed {
  background-color: #d83636;
  padding: 2px;
}
.pageCardTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #434343;
  letter-spacing: 0;
}
.pageCardDescription {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  font-size: 14px;
  color: #6a6a6a;
  min-height: 50px;
  letter-spacing: 0;
}

.pageCardArrow {
  color: #205072;
  opacity: 1;
}

/* Short Info Text */
.shortInfoTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
}

.shortInfoDescription {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  text-align: center;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
}

/* Used several places */

/* See All News / RSS Button */
.seeAllButtonText {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #205072;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  text-transform: initial;
}
.seeAllButtonBorder {
  border: 3px solid #205072;
  font-family: "Lato", sans-serif;
  opacity: 1;
  border-radius: 52px;
}
.seeAllButtonBorder:after {
  border: 3px solid #d6d2d24d;
  content: "";
  position: absolute;
  top: -9px;
  right: -9px;
  bottom: -9px;
  left: -9px;
  border-radius: 4em;
  box-shadow: 0px 2px 12px #00000033;
}

.aboutTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6a6a6a;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: center;
  background-color: #e0f1ff;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.whomBackground {
  background-color: #f2f2f2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
</style>
