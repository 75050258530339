<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Training and professional development
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          Links to the separate modules of the MOOC and other professional
          development opportunities for educators interested in enhancing their
          digital skills and knowledge.
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-3"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          descriptionOne:
            "Codecademy <a href='https://www.codecademy.com/' target='_blank'>https://www.codecademy.com/</a>: Codecademy offers interactive coding lessons on various programming languages, including Python, JavaScript, HTML/CSS, and more. <br /> They provide a free version with limited access and a subscription-based Pro version for additional features.",
          descriptionTwo:
            "Khan Academy <a href='https://www.khanacademy.org/' target='_blank'>https://www.khanacademy.org/</a>: Khan Academy offers a wide range of educational content, including computer programming courses. <br /> They provide free, self-paced coding lessons suitable for beginners.",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          descriptionOne:
            "Webwise - <a href='https://digipedzgen.eu/trainingmodules' target='_blank'>https://digipedzgen.eu/trainingmodules</a>",
          descriptionTwo:
            "Webwise is a Safety Awareness Centre. It offers information, advice and free education resources addressing the range of internet safety issues and concerns. <br /> It can be used not only by students, but by parents, teachers, close family and more. <br /> It offers guidelines, videos, presentations, hubs, training, even school programmes that include handbooks, animation and additional information.",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          descriptionOne:
            "<a href='https://edunation.com.pl/o-nas/' target='_blank'>https://edunation.com.pl/o-nas/</a> Edunation is an initiative of two people: Ania Poplawska and Luiza Wójtowicz-Waga. <br /> They are both language teachers, they deal with teaching methodology, they are teacher trainers. <br /> They are turned on by different things in education. <br /> Their common goal is: they want to create education full of joy, high education, they want to help teachers fulfill themselves and be happy people.",
          descriptionTwo:
            "<a href='https://onlinelearningconsortium.org/' target='_blank'>https://onlinelearningconsortium.org/</a> The Online Learning Consortium™ (OLC) is a collaborative community of higher education leaders and innovators, dedicated to advancing quality digital teaching and learning experiences designed to reach and engage the modern learner - anyone, anywhere, anytime.",
          descriptionThree:
            "<a href='https://www.open.ac.uk/courses/computing-it' target='_blank'>https://www.open.ac.uk/courses/computing-it</a> The Open University is a global leader in higher education able to reach every adult in the United Kingdom - and many others across the world.",
          descriptionFour:
            "<a href='https://etwinning.pl/' target='_blank'>https://etwinning.pl/</a> In eTwinning, teachers organize and run on-site and online activities with their students along with colleagues from countries participating in the Erasmus+ programme. <br /> They engage in collaborative projects with the support of the TwinSpace environment.",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          descriptionOne:
            "Resource: Google Digital Literacy and Safety Course Link: <a href='https://beinternetawesome.withgoogle.com/en_us/interland' target='_blank'>https://beinternetawesome.withgoogle.com/en_us/interland</a>",
          descriptionTwo:
            "Description: Google's 'Be Internet Awesome' initiative offers a free online course called 'Interland' that aims to teach students about digital literacy and online safety. <br /> Interland is an interactive game that covers topics like privacy, online etiquette, secure passwords, and identifying fake information. <br /> The game is designed to engage and educate students in a fun and interactive way, helping them develop critical digital skills while promoting responsible online behavior. <br /> The course also provides supplementary resources for educators to facilitate discussions and extend learning beyond the game. <br /> The link provided will lead you to the free access to the Interland game.",
          descriptionThree:
            " Resource: 'Google Digital Literacy Training' by Grow with Google <br />  <strong> Link: </strong>  <a href='https://learndigital.withgoogle.com/digitalgarage/course/digital-skills-for-teachers' target='_blank'>https://learndigital.withgoogle.com/digitalgarage/course/digital-skills-for-teachers</a>",
          descriptionFour:
            "Description: 'Google Digital Literacy Training' is a free online course designed for educators to develop their digital skills and enhance their teaching practices. <br /> The course covers topics such as online safety, digital citizenship, effective online communication, and data security. <br /> It provides interactive modules, practical exercises, and real-life examples to help educators integrate digital literacy into their classrooms. <br /> The course is self-paced and offers a certificate upon completion",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          descriptionOne:
            "Learn to design your digital skills training and how to implement a blended learning approach.",
          descriptionTwo:
            "<a href='https://priostraining.netlify.app/' target='_blank'>https://priostraining.netlify.app/</a>",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
