<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          How to provide motivation
        </p>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="stage in stages"
        :key="stage.value"
      >
        <v-card
          @click="displayPartnerDetails(stage.value)"
          :style="{ backgroundColor: stage.backgroundColor }"
        >
          <p class="text-center pa-5">{{ stage.partnerName }}</p>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
      </v-col>
      <!-- Title and Subtitle of the "Page" -->
      <v-col cols="12" v-if="selectedStage == 0"
        >Click a card above, to see more information
      </v-col>
      <v-col cols="12" v-if="selectedStage == 1">
        <StageOne />
      </v-col>
      <v-col cols="12" v-if="selectedStage == 2">
        <StageTwo />
      </v-col>
      <v-col cols="12" v-if="selectedStage == 3">
        <StageThree />
      </v-col>
      <v-col cols="12" v-if="selectedStage == 4">
        <StageFour />
      </v-col>
      <v-col cols="12" v-if="selectedStage == 5">
        <StageFive />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StageOne from "@/components/OutputSections/IO2/subIO2/StageOne.vue";
import StageTwo from "@/components/OutputSections/IO2/subIO2/StageTwo.vue";
import StageThree from "@/components/OutputSections/IO2/subIO2/StageThree.vue";
import StageFour from "@/components/OutputSections/IO2/subIO2/StageFour.vue";
import StageFive from "@/components/OutputSections/IO2/subIO2/StageFive.vue";
export default {
  components: {
    StageOne,
    StageTwo,
    StageThree,
    StageFour,
    StageFive,
  },
  data() {
    return {
      selectedStage: 0,
      stages: [
        {
          partnerName: "Access and Motivation",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: "Socialization",
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "Information Exchange",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Knowledge Construction",
          value: 4,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "Development",
          value: 5,
          backgroundColor: "#E8EAF6",
        },
      ],
    };
  },
  methods: {
    displayPartnerDetails(stage) {
      this.selectedStage = stage;
    },
  },
};
</script>

<style scoped>
.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}
</style>
