<template>
  <v-card class="ma-0" flat>
    <v-row>
      <!-- <v-col cols="12" class="ma-0 pa-0 pl-3 pr-3">
        <p class="title text-center pb-5 pt-5 mainHeaders">
          Digital tools and platforms
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
      </v-col> -->

      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Digital tools and platforms
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          All partners must provide short information about various digital
          tools and platforms hyperlinks(videos) that can be used in the
          educational context, such as:
        </p>

        <p class="ma-0 pa-0 pl-5">- learning management systems</p>
        <p class="ma-0 pa-0 pl-5">- collaboration tools</p>
        <p class="ma-0 pa-0 pl-5">- content creation tools</p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- Section 1 - Learning Management Systems (LMS) -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>

                <v-row class="ma-0 pa-0">
                  <!-- Learning Management Systems -->
                  <v-col class="ma-0 pa-0 mt-10" cols="12">
                    <p class="ml-5" style="font-size: 24px; font-weight: bold">
                      Learning Management Systems (LMS)
                    </p>
                  </v-col>
                  <v-col
                    class="ma-0 pa-0 mb-5"
                    v-for="partnerLMS in partnerData.LearningManagementSystem"
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                  >
                    <v-card class="ma-2" height="100%">
                      <v-card-title>{{ partnerLMS.title }}</v-card-title>
                      <v-card-text
                        v-html="partnerLMS.description"
                        class="pb-0"
                        style="min-height: 150px"
                      ></v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="primary"
                          :href="partnerLMS.link"
                          target="_blank"
                          v-if="partnerLMS.link"
                        >
                          Link
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <!-- Collaboration Tools -->
                  <v-col class="ma-0 pa-0 mt-10" cols="12">
                    <p class="ml-5" style="font-size: 24px; font-weight: bold">
                      Collaboration Tools
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 mb-5"
                    v-for="partnerCollaborationTools in partnerData.CollaborationTools"
                  >
                    <v-card class="ma-2" height="100%">
                      <v-card-title>{{
                        partnerCollaborationTools.title
                      }}</v-card-title>
                      <v-card-text
                        v-html="partnerCollaborationTools.description"
                        class="pb-0"
                        style="min-height: 150px"
                      ></v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="primary"
                          :href="partnerCollaborationTools.link"
                          target="_blank"
                          v-if="partnerCollaborationTools.link"
                        >
                          Link
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <!-- Content Creation Tools -->
                  <v-col class="ma-0 pa-0 mt-10" cols="12">
                    <p class="ml-5" style="font-size: 24px; font-weight: bold">
                      Content Creation Tools
                    </p>
                  </v-col>
                  <v-col
                    class="ma-0 pa-0 mb-5"
                    v-for="partnerContentCreationTools in partnerData.ContentCreationTools"
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                  >
                    <v-card class="ma-4" height="100%">
                      <v-card-title>{{
                        partnerContentCreationTools.title
                      }}</v-card-title>
                      <v-card-text
                        v-html="partnerContentCreationTools.description"
                        class="pb-0"
                        style="min-height: 150px"
                      ></v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="primary"
                          :href="partnerContentCreationTools.link"
                          target="_blank"
                          v-if="partnerContentCreationTools.link"
                        >
                          Link
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="ma-0 pa-0 mt-10"
                    v-if="partnerData.ContentCreationTololsText != ''"
                  >
                    <p v-html="partnerData.ContentCreationTololsText"></p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="ma-0 pa-0 mt-5 mb-10"
                    v-if="partnerData.MoreInfo != ''"
                  >
                    <p v-html="partnerData.MoreInfo"></p>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          LearningManagementSystem: [
            {
              title: "Canvas",
              link: "https://www.instructure.com/canvas/login/free-for-teacher",
              description:
                " Canvas is a popular cloud-based LMS that offers a user-friendly interface and a range of features for course creation, grading, communication, and collaboration. <br /> It provides mobile apps for easy access on various devices.",
            },
            {
              title: "Blackboard Learn",
              link: "https://elearn.mu-varna.bg/",
              description:
                "Blackboard Learn is a robust LMS that supports online and blended learning environments. <br /> It includes features like course creation, assignment management, grading, and integration with other tools and systems.",
            },
          ],
          CollaborationTools: [
            {
              title: "Slack",
              link: "https://slack.com/",
              description:
                "Slack is a popular team communication tool that provides channels for discussions, direct messaging, and file sharing. <br /> It can be used by teachers to communicate with colleagues, share resources, and collaborate on projects.",
            },
          ],
          ContentCreationTools: [
            {
              title: "Adobe",
              link: "https://www.adobe.com/bg/creativecloud.html?mv=search&mv=search&sdid=KH8NVGP5&ef_id=Cj0KCQjwyLGjBhDKARIsAFRNgW9Zj7FaG3LjZDWsvpSuMRUVPiy5KmHm7dn88Pl58RYHhLeFeoZPTTYaAiOLEALw_wcB:G:s&s_kwcid=AL!3085!3!474095410234!e!!g!!adobe%20creative%20cloud!11093006588!107686529926&gad=1",
              description:
                "Adobe Creative Cloud  offers a suite of powerful tools for content creation, including Photoshop, Illustrator, Premiere Pro, and InDesign. <br /> These tools enable teachers to create visually appealing presentations, graphics, videos, and interactive materials.",
            },
            {
              title: "Canva",
              link: "https://www.canva.com/",
              description:
                "Canva is a user-friendly graphic design platform that allows teachers to create engaging visual content, such as posters, infographics, and social media posts, without requiring extensive design skills.",
            },
            {
              title: "Nearpod",
              link: "https://nearpod.com/",
              description:
                "Nearpod is an interactive presentation tool that allows teachers to create multimedia-rich lessons with interactive activities, quizzes, and virtual reality experiences. <br /> It enhances student engagement and provides real-time feedback.",
            },
          ],
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          LearningManagementSystem: [
            {
              title: "Moodle",
              link: "https://moodle.org/",
              description:
                " Moodle is an open source learning platform. It enables you to create flexible, secure and accessible online spaces for students. <br /> It can be used in the face-to-face classroom, distance education, even for homeschooling. <br /> Moodle has a wide range of digital tools that can improve the engagement in the classroom <br /> - setting dates, earning badges, gamification and so much more.",
            },
            {
              title: "Canvas",
              link: "",
              description:
                "A user-friendly LMS that offers features like course creation, discussion boards, grading, and communication tools",
            },
            {
              title: "Google Classroom",
              link: "",
              description:
                "Google Classroom is a widely used platform in Bulgaria, particularly in 19. High school, that enables teachers to create and manage virtual classrooms, distribute assignments, and communicate with students. <br /> It offers features like assignment management, collaboration tools, integration with Google tools, grade tracking, and mobile accessibility.",
            },
            {
              title: "Shkolo",
              link: "",
              description:
                "Shkolo is bulgarian learning management system designed to provide a comprehensive online learning experience. <br /> It includes course management, collaboration tools, assessment and grading features, communication channels, customization options, analytics, reporting, and integration with third-party tools. <br /> It is not a real platform but an example used for illustration.",
            },
            {
              title: "Microsoft Teams",
              link: "",
              description:
                "A collaborative platform that offers features like virtual classrooms, chat, file sharing, and video conferencing, facilitating communication and collaboration among students and teachers",
            },
          ],
          CollaborationTools: [
            {
              title: "Flinga",
              link: "https://flinga.fi/",
              description:
                "Flinga is a collaborative tool that offers a whiteboard which can be used by pre-selected participants. <br /> This tool can be really useful for asking questions, collecting students' answers, giving comments on a topic, brainstorming, drawing etc. ",
            },
            {
              title: "Miro",
              link: "",
              description:
                "Miro is a collaborative online whiteboard that allows students and teachers to work together on visual projects and activities. <br /> It offers a wide range of templates, sticky notes, and drawing tools to facilitate brainstorming, mind mapping, and collaborative problem-solving",
            },
            {
              title: "Padlet",
              link: "",
              description:
                "Padlet is a digital bulletin board that enables collaborative brainstorming, note-taking, and content sharing. <br /> It provides a visual and interactive platform where students and teachers can post text, images, videos, and links, creating a collaborative workspace.",
            },
            {
              title: "Edpuzzle",
              link: "",
              description:
                "Edpuzzle is an interactive video platform designed for educational purposes. It allows teachers to customize existing videos or create their own interactive video lessons by adding questions, comments, and quizzes at specific points in the video. <br /> It empowers teachers to make videos more engaging and interactive, enabling them to assess students' understanding and provide targeted instruction based on their needs. <br /> It promotes active learning and student-centered engagement with video content.",
            },
          ],
          ContentCreationTools: [
            {
              title: "Pixton ",
              link: "https://www.pixton.com/",
              description:
                "Pixton is a comics creation site where both teachers and students can create ideas via comics. <br /> Everyone can create their own avatar, choose different backgrounds, add characters and design a story panel by panel. <br /> Teachers can use this tool to give their students assignments that are connected to storytelling, sequencing, creating content and context etc.",
            },
            {
              title: "Powtoon",
              link: "",
              description:
                "Powtoon is a platform that allows users to create animated videos and presentations. <br /> It provides a user-friendly interface with drag-and-drop functionality, pre-designed templates, characters, and animations. <br /> Powtoon is ideal for creating animated explanations, storytelling, and engaging presentations.",
            },
            {
              title: "Book Creator",
              link: "",
              description:
                "Book Creator is an intuitive digital publishing tool that allows users to create interactive ebooks and digital storybooks. <br /> It offers a range of multimedia options such as text, images, videos, audio, and drawings. <br /> Book Creator encourages creativity, storytelling, and collaboration among students and educators",
            },
          ],
          ContentCreationTololsText:
            "Learning apps have become increasingly popular in the educational landscape, providing interactive and engaging platforms for students to acquire knowledge and skills. <br /> These apps leverage the power of digital technology to enhance learning experiences and cater to individual learning styles",
          MoreInfo:
            "These content creation tools offer a wide range of creative possibilities, empowering educators and students to express themselves, present information in engaging formats, and enhance the learning experience through multimedia content.Teachers play a crucial role in selecting and integrating learning apps that align with educational goals and enhance the learning experience for their students.",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          LearningManagementSystem: [
            {
              title: "Google Workspace",
              link: "",
              description:
                " Google Workspace - a collection of office and productivity applications that connect for increased integration between work processes. <br /> Google Workspace includes many business applications that interoperate for increased collaboration and productivity in the workplace. <br /> This software also integrates with many other applications: for example, a business might sync their Google Sheets many more.",
            },
            {
              title: "Electronic class register",
              link: "https://lo14poznan.mobidziennik.pl/dziennik",
              description:
                "A clearly arranged environment for teachers to enter lesson topics, class attendance and even homework if they want. <br /> Provides the school management team with an instant overview of what is going on within the school. <br /> No more totting up absences, recording excused absences or hunting for the class register. <br /> All the data only needs to be entered once.",
            },
          ],
          CollaborationTools: [
            {
              title: "Padlet",
              link: "",
              description:
                "Padlet is a digital bulletin board that enables collaborative brainstorming, note-taking, and content sharing. <br /> It provides a visual and interactive platform where students and teachers can post text, images, videos, and links, creating a collaborative workspace.",
            },
            {
              title: "Google Workspace",
              link: "",
              description:
                "Google Workspace - a collection of office and productivity applications that connect for increased integration between work processes. <br /> Google Workspace includes many business applications that interoperate for increased collaboration and productivity in the workplace. <br /> This software also integrates with many other applications: for example, a business might sync their Google Sheets many more.",
            },
          ],
          ContentCreationTools: [
            {
              title: "Canva",
              link: "https://www.canva.com/",
              description:
                "Canva is a user-friendly graphic design platform that allows teachers to create engaging visual content, such as posters, infographics, and social media posts, without requiring extensive design skills.",
            },
            {
              title: "Wordwall",
              link: "https://wordwall.net/pl",
              description:
                "Wordwall can be used to create both interactive and printable activities. <br /> Most of the templates are available in both an interactive and a printable version.",
            },
            {
              title: "islCollective",
              link: "https://en.islcollective.com/",
              description:
                "A collection of free English ESL handouts pdf & doc, slides, video quizzes with reading & listening comprehension exercises, vocabulary & grammar.",
            },
            {
              title: "Quizizz",
              link: "https://quizizz.com/?lng=pl",
              description:
                "You can study on your own or engage in group quizzes, assignments, and presentations—in person and remotely. <br /> Quizizz is used by more than 20 million people per month in schools, homes, and offices around the world. <br /> The app is designed to help you participate in group activities and study on your own",
            },
            {
              title: "Liveworksheets",
              link: "https://www.liveworksheets.com/",
              description:
                "Liveworksheets allows you to transform your traditional printable worksheets (doc, pdf, jpg...) into interactive online exercises with self-correction, which we call 'interactive worksheets'. <br /> Students can do the worksheets online and send their answers to the teacher. <br /> This is good for the students (it's motivating), for the teacher (it saves time) and for the environment (it saves paper). <br /> Additionally, our interactive worksheets take full advantage of the new technologies applied to education: they may include sounds, videos, drag and drop exercises, join with arrows, multiple choice... and even speaking exercises that the students must do using the microphone.",
            },
          ],
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          LearningManagementSystem: [
            {
              title: "Google Classroom",
              link: "https://edu.google.com/workspace-for-education/classroom/",
              description:
                " Google Classroom is an all-in-one place for teaching and learning. <br /> It is an easy to use and secure tool which helps educators manage, measure and enrich learning experiences.",
            },
          ],
          CollaborationTools: [
            {
              title: "Flipgrid",
              link: "https://info.flip.com/en-us.html",
              description:
                "Flipgrid is a video discussion platform used by teachers and students on all levels of education. <br /> It is a free and accessible app that intends to improve students' learning by maximizing student talking time (STT). <br /> This is achieved through videos as the primary learning tool for students",
            },
          ],
          ContentCreationTools: [
            {
              title: "Canva",
              link: "https://www.canva.com/",
              description:
                "Canva  is an online graphic design platform. <br /> Its user-friendly content creation tools make beautiful design accessible. <br /> You can create blog images, social media videos, infographics, presentations, PDF reports, and much more. <br /> The free version comes with thousands of free templates.",
            },
          ],
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          LearningManagementSystem: [
            {
              title: "Moodle",
              link: "https://moodle.org/",
              description:
                "Moodle is a free and open-source LMS designed to help educators create effective online learning communities. <br /> It offers a wide range of features for designing and managing courses, including activity modules such as forums, databases, and quizzes",
            },
          ],
          CollaborationTools: [
            {
              title: "Microsoft Teams for Education",
              link: "https://www.microsoft.com/en-us/education/products/teams",
              description:
                "Microsoft Teams for Education is a digital hub that brings conversations, content, assignments, and apps together in one place, promoting collaboration among students and teachers. <br /> It integrates with Office 365 and allows video meetings, group chats, and file sharing.",
            },
          ],
          ContentCreationTools: [
            {
              title: "Adobe Spark for Education",
              link: "https://spark.adobe.com/edu/",
              description:
                "Adobe Spark for Education is a suite of free, cloud-based applications that allow students and teachers to create and share graphics, web pages, and short videos. <br /> Its user-friendly interface and extensive resources make it a powerful tool for enhancing classroom creativity.",
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
