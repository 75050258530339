<template>
  <div class="homePageWidth">
    <!-- <TrainingModulePage></TrainingModulePage> -->
    <TrainingPage></TrainingPage>
  </div>
</template>

<script>
import TrainingModulePage from "@/components/TrainingModule/TrainingModulePage.vue"
import TrainingPage from "@/components/TrainingModule/TrainingPage.vue"
export default {
  name: 'Training-Modules',
  components: {
    TrainingModulePage,
    TrainingPage
  },
  data(){
    return {
     
    }
  },
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}

</style>