<template>
  <div class="homePageWidth">
    <!-- <ResourcePage></ResourcePage> -->
    <ResourcePageV2></ResourcePageV2>
  </div>
</template>

<script>
import ResourcePage from "@/components/Resource/ResourcePage.vue"
import ResourcePageV2 from "@/components/Resource/ResourcePageV2.vue"
export default {
  name: 'Resource Page',
  components: {
    ResourcePage,
    ResourcePageV2
  },
  data(){
    return {

    }
  },
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}
</style>