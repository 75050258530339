O<template>
  <v-app-bar app class="navbarStyling" flat>
    <ContactForm ref="OpenContactForm"></ContactForm>
    
    <!-- <p v-if="$i18n.locale == 'en'">asd</p> -->
    <!-- Logo -->
    <!-- <div class="d-flex align-center">
      <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="img/DIGI_logo.png" transition="scale-transition" width="40"/>
      <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100"/>
    </div> -->
    <!-- <v-img src="img/DIGI_logo.png" height="40" width="260px" contain/> -->
    <v-spacer></v-spacer>
    <a href="/">
      <v-img class="mt-8 ml-10" src="img/digipedzgen_logo.png"  max-height="100" max-width="100" contain></v-img>
    </a>
    <v-spacer></v-spacer>
    <!-- Links -->
    <!-- 
      1. Logo
      2. Home Button
      3. About
      4. News
      5. Training Modules
      6. Digital Learning Design
      7. Digital Pedagogical Strategy
      8. Contact (dialog)
      9. Login (Link to followup? OR same as valuable creativity) 
    -->
    <div class="mt-10">
      <a href="/">Home</a>
      <!-- <a href="/about">About</a> -->
      <a href="/partners">Partners</a>
      <!-- <a href="/news">News</a> -->
      <!-- <a href="/rss">RSS</a> -->
      <a href="/outputs">Outputs</a>
      <a href="/trainingmodules">Mooc</a>
      <a href="/mappingtool">Mapping tool</a>
      <a href="/resources" class="mr-2">Resources</a>

      <!-- <v-btn text to="/">{{ $t('navbar.home') }}</v-btn>
      <v-btn text to="/about">{{ $t('navbar.about') }}</v-btn>
      <v-btn text to="/news">{{ $t('navbar.news') }}</v-btn>
      <v-btn text to="/trainingmodules">Training Modules</v-btn>
      <v-btn text to="/resources">Resources</v-btn> -->
      <!-- <v-btn text to="/digitallearningdesign">Digital Learning Design</v-btn> -->
      <!-- <v-btn text to="/digitalpedagogicalstrategy">Digital Pedagogical Strategy</v-btn> -->
      <!-- <v-btn class="contactButton" small text @click="$refs.OpenContactForm.openContactDialog()">Contact</v-btn> -->
      <span class="contactButton" style="color: #33691E;" @click="$refs.OpenContactForm.openContactDialog()">Contact</span>
      <!-- <v-btn icon @click="$refs.OpenContactForm.openContactDialog()">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn> -->
    </div>
    <!-- <div class="mt-10">
      <LanguageSelection></LanguageSelection>
    </div> -->


    <v-spacer></v-spacer>
    <!-- Login -->
    <!-- <div class="mt-7">
      <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">Login</v-btn>
    </div> -->
  </v-app-bar>
</template>

<script>
import ContactForm from "@/components/Global/ContactUs.vue"
import LanguageSelection from "@/components/TestAndDevelopment/SelectLocale.vue"

export default {
  components:{
    ContactForm,
    LanguageSelection
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>
.navbarStyling{
  height:6em !important; 
  width: 90vw !important; 
  margin: auto auto !important; 
  background-color:#FFFFFF !important; 
  border-bottom:5px solid #205072!important;
}
a {
  padding: 14px;
  /* color: #6A6A6A; */
  color: #33691E;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
}

a:link {
  /* color: #6A6A6A; */
  color: #33691E;
}
a:visited {
  /* color: #6A6A6A; */
  color: #33691E;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
/* a:active {
  background-color:black;
  color: orangered;
} */

/* Contact Button */
.contactButton {
  font-family: 'Lato', sans-serif;
  font-weight: regular;
  color: #6A6A6A;
  line-height: 1em;
  font-size: 16px;
}

.contactButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>