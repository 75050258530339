<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Training and professional development
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          Links to the separate modules of the MOOC and other professional
          development opportunities for educators interested in enhancing their
          digital skills and knowledge.
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionFive"
                  v-html="partnerData.descriptionFive"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionSix"
                  v-html="partnerData.descriptionSix"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionSeven"
                  v-html="partnerData.descriptionSeven"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionEight"
                  v-html="partnerData.descriptionEight"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionNine"
                  v-html="partnerData.descriptionNine"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTen"
                  v-html="partnerData.descriptionTen"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionEleven"
                  v-html="partnerData.descriptionEleven"
                  class="mb-3"
                ></p>
                <p
                  v-if="partnerData.descriptionTwelve"
                  v-html="partnerData.descriptionTwelve"
                  class="mb-3"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "ValueMe",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          backgroundColor: "#E8F5E9",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          backgroundColor: "#EFEBE9",
        },
      ],
      partnerInfo: [
        {
          partnerName: "ValueMe",
          value: 1,
          descriptionOne:
            "<a href='https://joint-research-centre.ec.europa.eu/digcompedu_en' target='_blank'>https://joint-research-centre.ec.europa.eu/digcompedu_en</a> The European Framework for the Digital Competence of Educators (DigCompEdu) provides a reference framework for educators' digital competence. <br /> It outlines the key competences that educators need to develop in order to effectively integrate digital technologies in teaching and learning.",
          descriptionTwo:
            "<a href='https://education.ec.europa.eu/selfie' target='_blank'>https://education.ec.europa.eu/selfie</a> The SELFIE (Self-reflection on Effective Learning by Fostering the use of Innovative Educational Technologies) tool is an initiative by the European Commission that allows schools to self-assess their digital readiness and identify areas for improvement. <br /> It provides a framework for schools to reflect on their use of technology in teaching and learning.",
        },
        {
          partnerName: '19 High school "Elin Pelin"',
          value: 2,
          descriptionOne:
            "Digital Education Action Plan - <a href='https://education.ec.europa.eu/focus-topics/digital-education/action-plan' target='_blank'>https://education.ec.europa.eu/focus-topics/digital-education/action-plan</a>",
          descriptionTwo:
            "The Digital Education Action Plan (2021 - 2027) is a renewed European Union initiative that sets out a common vision of high-quality, inclusive and accessible digital education in Europe. <br />It aims to support the adaptation of the education and training systems. ",
          descriptionThree:
            "Digital Bulgaria 2025 - <a href='https://digital-skills-jobs.europa.eu/en/actions/national-initiatives/national-strategies/bulgaria-digital-bulgaria-2025-national-programme' target='_blank'>https://digital-skills-jobs.europa.eu/en/actions/national-initiatives/national-strategies/bulgaria-digital-bulgaria-2025-national-programme</a>",
          descriptionFour:
            "The Digital Bulgaria 2025 Programme aims to modernize and increase the implementation of intelligent information technology (IT) and enhance the digital competence and skills at all levels.",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 3,
          descriptionOne:
            "<a href='https://www.gov.pl/web/cyfryzacja/ogolnopolska-siec-edukacyjna1' target='_blank'>https://www.gov.pl/web/cyfryzacja/ogolnopolska-siec-edukacyjna1</a> The project of the National Educational Network assumes the creation of an Internet access network connecting all schools in Poland (about 30,500). <br /> The OSE will be a virtual network based on the existing broadband infrastructure (built entirely with commercial funds as well as with the participation of public funding, in particular the Digital Poland Operational Program).",
          descriptionTwo:
            "<a href='https://ceo.org.pl/blog' target='_blank'>https://ceo.org.pl/blog</a>",
          descriptionThree:
            "<a href='https://www.gov.pl/web/edukacja-i-nauka/program-wsparcia-edukacji' target='_blank'>https://www.gov.pl/web/edukacja-i-nauka/program-wsparcia-edukacji</a>",
          descriptionFour:
            "The program includes funding projects for the following activities: <br /> - creation of interactive teaching aids and innovative educational materials; <br /> - organizing extracurricular and out-of-school activities, including those shaping social activity and leisure skills <br /> - organizing sports competition between schools; <br /> - organizing events, including: competitions, exhibitions, presentations, workshops, lectures, sports and recreational events, seminars, conferences, rallies; <br /> - other activities aimed at supporting the educational system.",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 4,
          descriptionOne:
            "Lithuanian Ministry of Education, Science, and Sport - Digital Agenda for Education: <br /> Website: <a href='https://www.smm.lt/en/education/digital-agenda-for-education/' target='_blank'>https://www.smm.lt/en/education/digital-agenda-for-education/</a>",
          descriptionTwo:
            "Description: The Lithuanian Ministry of Education, Science, and Sport has a dedicated section on their website that outlines the Digital Agenda for Education. <br /> It provides information on national policies, strategies, and initiatives related to digitalization in schools, including guidelines for integrating technology into teaching and learning.",
          descriptionThree:
            "European Commission - Digital Education Action Plan: <br />  Website: <a href='https://ec.europa.eu/education/education-in-the-eu/digital-education-action-plan_en' target='_blank'>https://ec.europa.eu/education/education-in-the-eu/digital-education-action-plan_en</a>",
          descriptionFour:
            "Description: The European Commission has developed a Digital Education Action Plan to promote the use of digital technologies in education across European Union member states. <br /> The website provides access to the action plan, along with related documents, guidelines, and resources that support the implementation of digital education in schools.",
          descriptionFive:
            "European Schoolnet - Policy and Strategy: <br /> Website: <a href='https://www.eun.org/policy-and-strategy' target='_blank'>https://www.eun.org/policy-and-strategy</a>",
          descriptionSix:
            "Description: European Schoolnet is a network of European Ministries of Education that promotes collaboration and innovation in education. <br /> Their website offers resources and information on policies and strategies related to digitalization in schools. <br /> It includes reports, case studies, and guidelines that highlight best practices and initiatives from different European countries.",
          descriptionSeven:
            "DigCompEdu - The European Digital Competence Framework for Educators: <br /> Website: <a href='https://ec.europa.eu/jrc/en/digcompedu' target='_blank'>https://ec.europa.eu/jrc/en/digcompedu</a>",
          descriptionEight:
            "Description: DigCompEdu is a framework developed by the European Commission that outlines the digital competencies educators need to effectively integrate digital technologies into their teaching practices. <br /> The website provides access to the framework, along with supporting materials and resources for educators to enhance their digital skills.",
          descriptionNine:
            "National Digital Coalition - Lithuania <br /> Website: <a href='https://www.smm.lt/en/education/digital-agenda-for-education/national-digital-coalition/' target='_blank'>https://www.smm.lt/en/education/digital-agenda-for-education/national-digital-coalition/</a>",
          descriptionTen:
            "Description: The National Digital Coalition in Lithuania is a partnership between the government, educational institutions, and organizations that aims to promote digital skills and digitalization in education. <br /> Their website offers information on national initiatives, programs, and guidelines related to digitalization in schools, along with resources for teachers, students, and parents.",
          descriptionEleven:
            "<a href='http://www.eun.org/documents/411753/839549/Country+Report+Lithuania+2017.pdf/dd707697-196e-4c33-ba03-254f3698ea23' target='_blank'>http://www.eun.org/documents/411753/839549/Country+Report+Lithuania+2017.pdf/dd707697-196e-4c33-ba03-254f3698ea23</a>",
          descriptionTwelve:
            "<a href='https://www.gabinetecomunicacionyeducacion.com/sites/default/files/field/investigacion-adjuntos/lithuaniad.pdf' target='_blank'>https://www.gabinetecomunicacionyeducacion.com/sites/default/files/field/investigacion-adjuntos/lithuaniad.pdf</a>",
        },
        {
          partnerName: "Prios Kompetanse",
          value: 5,
          descriptionOne: "Digital skills as a basic skill",
          descriptionTwo:
            "Norwegian framework for digital skills in education. <br /> Digital skills are a natural part of the basis for learning work both within and across academic subjects",
          descriptionThree:
            "<a href='https://www.udir.no/laring-og-trivsel/rammeverk/rammeverk-for-grunnleggende-ferdigheter/2.1-digitale-ferdigheter/' target='_blank'>https://www.udir.no/laring-og-trivsel/rammeverk/rammeverk-for-grunnleggende-ferdigheter/2.1-digitale-ferdigheter/</a>",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
