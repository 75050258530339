<template>
  <v-card class="ma-0" flat>
    <v-row>
      <v-col cols="12">
        <p class="text-center title pb-5 pt-5 mb-5 mainHeaders">
          Case studies and best practices
        </p>
        <v-divider
          class="smartGoalDividerPositioning"
          width="98%"
          style="padding: 2px"
        ></v-divider>
        <p>
          All schools showcase the examples of successful workshops and best
          practices
        </p>
      </v-col>

      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        v-for="partner in partners"
        :key="partner.value"
      >
        <v-card
          @click="displayPartnerDetails(partner.value)"
          :style="{ backgroundColor: partner.backgroundColor }"
        >
          <p class="text-center pa-5">{{ partner.partnerName }}</p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Partner Info -->
        <template v-if="partnerToDisplay != 0">
          <v-divider
            class="smartGoalDividerPositioning"
            width="98%"
            style="padding: 2px"
          ></v-divider>
          <v-row class="ma-0 pa-0">
            <!-- More info about Partners -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
              xl="10"
              lg="10"
              md="10"
              sm="12"
              xs="12"
              v-for="partnerData in partnerInfo"
              :key="partnerData.value"
            >
              <template v-if="partnerData.value == partnerToDisplay">
                <p
                  class="text-center mb-10"
                  style="font-size: 36px; font-weight: bold"
                >
                  {{ partnerData.partnerName }}
                </p>
                <p
                  v-if="partnerData.descriptionOne"
                  v-html="partnerData.descriptionOne"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionTwo"
                  v-html="partnerData.descriptionTwo"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionThree"
                  v-html="partnerData.descriptionThree"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionFour"
                  v-html="partnerData.descriptionFour"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionFive"
                  v-html="partnerData.descriptionFive"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionSix"
                  v-html="partnerData.descriptionSix"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionSeven"
                  v-html="partnerData.descriptionSeven"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionEight"
                  v-html="partnerData.descriptionEight"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionNine"
                  v-html="partnerData.descriptionNine"
                  class="mb-2"
                ></p>
                <p
                  v-if="partnerData.descriptionTen"
                  v-html="partnerData.descriptionTen"
                  class="mb-10"
                ></p>
              </template>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDisplayed: false,
      partnerToDisplay: 0,
      partners: [
        {
          partnerName: "19 High school 'Elin Pelin' - Mirela",
          value: 1,
          backgroundColor: "#F3E5F5",
        },
        {
          partnerName: 'XIV Liceum Ogólnokształcące"',
          value: 2,
          backgroundColor: "#FCE4EC",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 3,
          backgroundColor: "#E8EAF6",
        },
      ],
      partnerInfo: [
        {
          partnerName: "19 High school 'Elin Pelin' - Mirela",
          value: 1,
          descriptionOne:
            "Within the framework of the European project 'Digital pedagogical strategy for Z generation,' two multidisciplinary lessons were conducted at 19th Secondary School 'Elin Pelin':  How to design collaborative projects and make learners creators of content.",
          descriptionTwo:
            "The outcomes of the lessons were twofold. Firstly, students gained a deeper understanding of environmental issues and the importance of sustainable living. <br /> They developed critical thinking skills, collaboration abilities, and an awareness of their role in preserving the environment.",
          descriptionThree:
            "Secondly, students were inspired to take action and make positive changes in their own lives to contribute to a more sustainable future.",
          descriptionFour:
            "In order to disseminate the project's results, a workshop titled 'Digital Pedagogical Strategy for the Digitization of Generation Z in Our School - Development Guide' was held on May 2, 2023. <br /> The event was open to all teachers and education specialists, and colleagues from different schools attended",
          descriptionFive:
            "The workshop showcased the materials created thus far, aimed at establishing a friendly community of schools with a clear digital strategy to enhance the pedagogical competencies of teachers. <br /> These materials will contribute to the quality of inclusive education and enrich the content with online resources and tools.",
          descriptionSix:
            "The workshop was facilitated by Angelina Sekulova and Mirela Petkova. The following results were presented:",
          descriptionSeven:
            "-> Development Guide for a Digital School Strategy and sharing of experiences from the conducted lessons.",
          descriptionEight:
            "-> Opportunities for testing a web-based tool for identifying current needs in digitizing the teaching process.",
          descriptionNine:
            "This case study highlights the importance of implementing a digital pedagogical strategy to meet the needs of Generation Z students and enhance the quality of education. <br /> The workshop served as a platform for knowledge exchange, showcasing practical materials and tools that can assist educators in developing effective digital strategies.",
          descriptionTen:
            "By disseminating these results and fostering a collaborative environment, the case study promotes the integration of digital technology in education and supports the professional growth of teachers.",
        },
        {
          partnerName: "XIV Liceum Ogólnokształcące",
          value: 2,
          descriptionOne:
            "Our school organized workshops for teachers of all specialities. <br /> 20 teachers participated in the workshops on 5th June. <br /> The event was a perfect opportunity to disseminate the outputs that have been produced so far.",
          descriptionTwo:
            "We presented the project website and its content, strategy manual and the translation, e-activities, pilot lessons scenarios.<br /> The lessons carried out were in:",
          descriptionThree:
            "- history <br /> - “Technological autonomy or the new priests of the scriptures <br /> - AI vs. New middle ages”. <br /> General objectives of the lesson: developing the ability and skills to work in a group (cooperation, negotiation, compromise, self-presentation, giving support, distributing and delegating tasks). <br /> Students realize that history is not only a collection of objective facts or events but also an ethical challenge and the ability to predict phenomena.",
          descriptionFour:
            "- geography - “Advantages and disadvantages of different modes of transport”. <br /> Objectives of the lesson: establishing cooperation in the group, listing the types of means of transport, discussing the different types of transport, presenting advantages and disadvantages.",
        },
        {
          partnerName: "Vilniaus Grigiskiu Sviesos gimnazija",
          value: 3,
          descriptionOne:
            "On May 30th, 2023, we organized a workshop called Digital Pedagogical Strategy for the Digitization of Generation Z in our school to share the results of our project. <br /> The workshop was attended by school teachers, education specialists.",
          descriptionTwo:
            "The key highlights from the workshop would be sharing a Development Guide for a Digital School Strategy and discussing our experiences from the lessons conducted. <br /> Participants, teachers of different subjects,  prepared the lesson plans in reference to their experience and knowledge they have received from the workshop.",
          descriptionThree:
            "We analyzed the materials we have created so far, which aim to create a community of schools with a clear digital strategy to improve teachers pedagogical skills. <br /> Our aim was to promote the integration of digital technology in education and support the professional growth of teachers.",
        },
      ],
    };
  },
  methods: {
    toggleIntroText() {
      if (this.isDisplayed == false) {
        this.isDisplayed = true;
      } else {
        this.isDisplayed = false;
      }
    },
    displayPartnerDetails(partnerValue) {
      this.partnerToDisplay = partnerValue;
    },
  },
};
</script>

<style scoped>
.closeButtonStyling {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.returningToTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  border: 1px solid black;
  background-color: rgba(255, 0, 0, 0.2);
}
.outputsTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 50px;
}
.smartGoalDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

p {
  margin-bottom: 0px;
}

.mainHeader {
  color: purple;
  font-weight: bold;
  background-color: lightyellow;
}

.subHeaders {
  color: orange;
  font-weight: bold;
  background-color: rgb(19, 79, 113);
}

.mainHeaders {
  color: rgb(19, 79, 113);
  font-weight: bold;
  background-color: orange;
}

.dropDownText {
  color: orange;
  font-weight: bold;
  cursor: pointer;
  border-bottom: solid 2px black;
  font-size: 22px;
}
</style>
