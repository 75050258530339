<template>
  <div>
     <!-- Read the README for more info -->
    <!-- <TrainingDialog ref="openTrainingDialog"></TrainingDialog> -->
    <TrainingDialogTwo ref="openTrainingDialogTwo"></TrainingDialogTwo>

    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12">
        <p class="trainingChapterTitle">Mooc</p>
        <!-- <p class="title">Mooc</p> -->
        <!-- <p class="trainingChapterDescription">
          Our partnership is still working on the curriculum, resources center and the training materials.  <br>
          Once they are available, you will be able to find them here.
          <br>
          Below you will find an example of one such module.
        </p> -->
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(courseCollection, index) in courseCollections" :key="index" class="pa-3">
        <v-card class="pa-2" @click="$refs.openTrainingDialogTwo.openDialog(courseCollection)" height="100%">
          <v-img v-if="courseCollection.background_image" :src="courseCollection.background_image" ></v-img>
          <p class="pt-3 text-center title">{{courseCollection.name}}</p>
          <p class="textLimiter">{{courseCollection.description}}</p>
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-15"></v-col>
    </v-row>
  </div>
</template>


<script>
// import TrainingDialog from "./TrainingModuleDialog.vue"
import TrainingDialogTwo from "./TrainingModuleDialogTwo.vue"
export default {
  components:{
    // TrainingDialog,
    TrainingDialogTwo
  },
  data(){
    return{
      // Access Key to followup API
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
      // 73 - default 999
      tenant: 107,
      courseCollections:[],
    }
  },
  mounted(){
    this.getAllCourseCollections();
    document.title = "Train the trainers in LifeSkills";
  },
  methods:{
    // Get all the courses to display on the page
    getAllCourseCollections(){
      this.courseCollections = [];
      this.$http.get(`https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${this.tenant}&user_id=${null}`,{headers:{tempaccess:this.accessKey}}).then(response =>{
        response.data.forEach(item =>{
          item.collection_content = []
          this.courseCollections.push(item);
        })
      })
    },
  },
  
  computed: {
    FilteredCoursesByID() {
      return this.courseCollections.filter(post => {

        // return post.id != 67 
        // && post.id != 66
        // && post.id != 65
        // && post.id != 68
        // && post.id != 72

         return post.id == 67
         || post.id == 66
         || post.id == 65
         || post.id == 68
         || post.id == 72
         || post.id == 83
         || post.id == 82
         || post.id == 71
      }) 
    },
  }
}
</script>


<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}

.trainingChapterTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.trainingChapterDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

</style>